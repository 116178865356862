import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
const firebaseConfig = {
    apiKey: "AIzaSyD6RlPviRZIqzNNLnyzHhdl5GMFN8-6y28",
    authDomain: "wedding-photo-sharing.firebaseapp.com",
    projectId: "wedding-photo-sharing",
    storageBucket: "wedding-photo-sharing.appspot.com",
    messagingSenderId: "796865501398",
    appId: "1:796865501398:web:4e1b7a9a5ea447cfa51177"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);