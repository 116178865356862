import React, { useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropdownButton = (props) => {
  const handleOptionChange = (option) => {
    props.setSelectedOption(option);
  };

  // Use useMemo to prevent resetting selectedOption on re-renders
  const memoizedSelectedOption = useMemo(() => props.selectedOption, [props.selectedOption]);

  return (
    <div className="dropdown pt-3">
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={props.isUploading}
      >
        {memoizedSelectedOption}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button
          className={`dropdown-item ${props.selectedOption === 'Images' ? 'active' : ''}`}
          onClick={() => handleOptionChange('Images')}
        >
          Images
        </button>
        <button
          className={`dropdown-item ${props.selectedOption === 'Videos' ? 'active' : ''}`}
          onClick={() => handleOptionChange('Videos')}
        >
          Videos
        </button>
      </div>
    </div>
  );
};

export default DropdownButton;