import React from 'react';
import Gallery from './components/Gallery';
import 'bootstrap/dist/css/bootstrap.min.css';
import wheat from './images/wheat.png'
import olive from './images/olive.png'
import "./App.css";

const App = () => {
  return (
    <div className="app-background">
      <div className="container-fluid heading-1">
        <img src={wheat} alt="wheat" className="header-img" />
        <div className="title mt-2">
          <h1 className="text-center title-a-m">Ana & Mateo's</h1>
          <h4 className="text-center">wedding day</h4>
        </div>
        <img src={olive} alt="olive" className="header-img" />
      </div>
      <Gallery />
    </div>
  );
};

export default App;