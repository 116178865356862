import React from 'react'
import Spinner from './Spinner'

export default function ProgressBar(props) {
    let widthNum = `${(props.numberOfImg * 100) / props.totalNumberOfImg}%`
    return (
        <>
            {props.totalNumberOfImg > 0 && <div>
                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={props.numberOfImg} aria-valuemin="0" aria-valuemax={props.totalNumberOfImg}>
                    <div className="progress-bar" style={{ width: widthNum }}></div>
                </div>
                <span className="p-2">{`${props.numberOfImg} / ${props.totalNumberOfImg}`}</span>
                <Spinner></Spinner>
            </div>}

            {props.totalNumberOfImg === 0 && <Spinner></Spinner>}
        </>
    )
}
