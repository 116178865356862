import {
  getDownloadURL,
  listAll,
  ref,
  uploadBytes
} from "firebase/storage";
import React, { useEffect, useState } from 'react';
import { v4 } from "uuid";
import { storage } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBar from './ProgressBar';
import Lightbox from 'react-image-lightbox';
import DropdownButton from './DropdownButton'
import 'react-image-lightbox/style.css';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

const Gallery = () => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberOfImg, setNumberOfImg] = useState(1);
  const imagesListRef = ref(storage, "images/");
  const [totalImagesToUpload, setTotalImagesToUpload] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Images');
  const [isVideoLightboxOpen, setVideoLightboxIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const openVideo = (url) => {
    setVideoUrl(url);
    setVideoLightboxIsOpen(true);
  };

  const closeVideo = () => {
    setVideoLightboxIsOpen(false);
    setVideoUrl('');
  };

  const uploadFile = async () => {
    if (imageUpload == null || imageUpload.length === 0) {
      toast.warn('No file selected', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setTotalImagesToUpload(imageUpload.length);
    setLoading(true);
    setIsUploading(true);
    for (let i = 0; i < imageUpload.length; i++) {
      let num = i + 1;
      setNumberOfImg(num);
      let image = imageUpload[i];
      const imageRef = ref(storage, `images/${image.name + v4()}`);
      const snapshot = await uploadBytes(imageRef, image);
      const url = await getDownloadURL(snapshot.ref);
      if (isImage(url)) {
        setImageUrls((prev) => [...prev, url]);
      }
      if (isVideo(url)) {
        setVideoUrls((prev) => [...prev, url]);
      }
    }

    toast.success('Saved', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    setLoading(false);
    setIsUploading(false);
    const form = document.querySelector('form');
    form.reset();
  };

  const fetchData = async () => {
    try {
      const response = await listAll(imagesListRef);
      const downloadUrlPromises = response.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return url;
      });

      const urls = await Promise.all(downloadUrlPromises);
      transformResult(urls);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching and setting image URLs:', error);
      setLoading(false);
    }
  }

  const isImage = (url) => {
    const imageFileStringRegex = /(jpg|jpeg|png|gif|bmp|webp)/i;
    return imageFileStringRegex.test(url);
  }

  const isVideo = (url) => {
    const videoFileStringRegex = /(mp4|mov|avi|mkv|flv|wmv|webm)/i;
    return videoFileStringRegex.test(url);
  }

  const transformResult = (urls) => {
    for (let url of urls) {
      if (isImage(url)) {
        setImageUrls((prev) => [...prev, url]);
      }
      if (isVideo(url)) {
        setVideoUrls((prev => [...prev, url]));
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="action-bar pb-3 mt-3 ms-3">
        <form>
          <input
            type="file"
            accept="image/*,video/*"
            disabled={isUploading}
            multiple
            onChange={(event) => {
              setImageUpload(event.target.files);
            }}
          />
        </form>
        <button className="btn btn-primary" onClick={uploadFile} disabled={isUploading} >
          {!isUploading && <span>Upload</span>}
          {isUploading && <span>Uploading...</span>}
        </button>
        <DropdownButton selectedOption={selectedOption} setSelectedOption={setSelectedOption} isUploading={isUploading} />
      </div>
      {!loading && selectedOption === "Images" && <div className="image-list">
        {imageUrls.map((url, index) => {
          return <div className="img-container rounded-3" key={index}
            onClick={() => {
              setLightboxIndex(index);
              setIsLightboxOpen(true);
            }}>
            <img src={url} alt="" className="p-1 g-col-6 rounded-3 img-thumbnail"
            />

          </div>
        })}
      </div>}
      {!loading && selectedOption === "Videos" && <div className="image-list">
        {videoUrls.map((url, index) => {
          return <div className="img-container rounded-3" key={index} /* onClick={() => openVideo(url)}*/>
            <video controls className="p-1 g-col-6 rounded-3 img-thumbnail">
              <source src={url} />
            </video>
          </div>
        })}
      </div>}
      {loading && <div className="m-3">
        <ProgressBar numberOfImg={numberOfImg} totalNumberOfImg={totalImagesToUpload}></ProgressBar>
      </div>}
      <ToastContainer />
      {isLightboxOpen && (
        <Lightbox
          mainSrc={imageUrls[lightboxIndex]}
          nextSrc={imageUrls[(lightboxIndex + 1) % imageUrls.length]}
          prevSrc={
            imageUrls[
            (lightboxIndex + imageUrls.length - 1) % imageUrls.length
            ]
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + imageUrls.length - 1) % imageUrls.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % imageUrls.length)
          }
        />
      )}
      <ModalVideo
        channel="custom"
        isOpen={isVideoLightboxOpen}
        url={videoUrl}
        onClose={closeVideo}
      />
    </div>
  );
};

export default Gallery;